import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import _map from 'lodash/map';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  PostLink,
  Banner,
} from '../components/Elements';

const mentionsLegalesQuery = graphql`
  {
    
   banner: file(relativePath: { eq: "bandeau_bla-bla.png" }) {
      childImageSharp {
          fluid( maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }

      }
    }

    page: datoCmsPageMentionlegale {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;




export default function MentionsLegalesPage() {
  const data = useStaticQuery(mentionsLegalesQuery);
  const { titre, contenu, seoMetaTags } = data.page;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper>
        <Banner height="150px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
          <PageTitle>{titre}</PageTitle>
          <div dangerouslySetInnerHTML={{ __html: contenu }} />
          {/*
          <Link to="/">
            <button css={{ marginLeft: '.5em' }}>Accueil</button>
          </Link>
          */}
        </PageInner>
      </PageWrapper>
    </Fragment>
  );
}
